import React, { Component } from 'react'
import { Box, Card, Typography, Link } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

class OwnerSection extends Component {

    constructor(props) {
        super(props)
    }


    render() {
        return(
        <ThemeProvider theme={theme}>
        <Card sx={{ m: 2 }}>
            <Box sx= {{ m: 2}}>
                <Typography variant="h6">🪧 Owner</Typography>
                <Typography variant="body1">{"Organisation Name:"} {this.props.electricCharger.DeviceOwner.OrganisationName}</Typography>
                <Typography variant="body1">{"Website:"} <Link href={this.props.electricCharger.DeviceOwner.Website}>{this.props.electricCharger.DeviceOwner.Website}</Link></Typography>
                <Typography variant="body1">{"Phone No:"} <Link href={"tel:" + this.props.electricCharger.DeviceOwner.TelephoneNo.trim()}> {this.props.electricCharger.DeviceOwner.TelephoneNo}</Link></Typography>
            </Box>
        </Card>
        </ThemeProvider>)
    }
}

export default OwnerSection