import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export let purpleColour = "#673ab7"

export const theme = responsiveFontSizes(createTheme ({
  palette: {
    mode: 'light',
    primary: {
      main: '#673ab7',
    },
    secondary: {
      main: '#f50057',
    },
  },
}));