import React, { Component } from 'react'
import { Box, Card, Typography, Link } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

class ControllerSection extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return(
        <ThemeProvider theme={theme}>
            <Card sx={{ m: 2 }}>
                <Box sx= {{ m: 2}}>
                <Typography variant="h6">🎮 Controller</Typography>
                <Typography> {"Organisation Name:"} {this.props.electricCharger.DeviceController.OrganisationName}</Typography>
                <Typography> {"Website:"} <Link href={this.props.electricCharger.DeviceController.Website}>{this.props.electricCharger.DeviceController.Website}</Link></Typography>
                <Typography> {"Phone No:"} <Link href={"tel:" + this.props.electricCharger.DeviceController.TelephoneNo.trim()}> {this.props.electricCharger.DeviceController.TelephoneNo}</Link></Typography>
                </Box>
            </Card>
        </ThemeProvider>)
    }
}

export default ControllerSection