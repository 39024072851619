import React from 'react'
import { Button, Stack, Skeleton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

function Filters(props) {

    const handleFilterUpdate = (event) => {
        props.onFilterSelected(event.target.value);
      };

    if (props.connectorTypes.length == 0) {
        return <Stack sx={{ m: 2}} spacing={1}>
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        </Stack>
    } else {
        const chargingConnectorTypes = props.connectorTypes
        return (
            <ThemeProvider theme={theme}>
            <FormControl sx={{ m: 1}}>
                <FormLabel>Connector Type</FormLabel>
                <RadioGroup column
                value={props.connectorTypeId}
                onChange={handleFilterUpdate}>
                    {
                    Object.keys(chargingConnectorTypes).map((key) => 
                        <FormControlLabel 
                            key={chargingConnectorTypes[key].ConnectorTypeID} 
                            control={<Radio/>} 
                            value={chargingConnectorTypes[key].ConnectorType}
                            label={chargingConnectorTypes[key].ConnectorType} />
                    )
                    }
                </RadioGroup>
                <Button onClick= { e =>
                    {
                        e.stopPropagation()
                        props.onClearFilterClicked()
                    }
                } variant="contained">Clear</Button>
            </FormControl>
            </ThemeProvider>
        );
    }
}

export default Filters