
import React, { Component } from 'react'
import { Stack, Card, Typography } from '@mui/material';

class LocationSection extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return(
            <Card sx= {{ m: 2}}>
            <Stack sx= {{ m: 2}}>
                <Typography variant="h6"> 🗺️ Address</Typography>
                <Typography>{this.props.electricCharger.ChargeDeviceLocation.Address.Street}</Typography>
                {this.props.electricCharger.ChargeDeviceLocation.Address.BuildingName != null && this.props.electricCharger.ChargeDeviceLocation.Address.BuildingName.trim() !== "" &&
                <Typography variant="body1">{"Building Name:"} {this.props.electricCharger.ChargeDeviceLocation.Address.BuildingName}</Typography>
                }
                <Typography variant="body1">{this.props.electricCharger.ChargeDeviceLocation.Address.PostTown}</Typography>
                <Typography variant="body1">{this.props.electricCharger.ChargeDeviceLocation.Address.County}</Typography>
                <Typography variant="body1">{this.props.electricCharger.ChargeDeviceLocation.Address.PostCode}</Typography>
            </Stack>
        </Card>
        )
    }
}

export default LocationSection;