import React, { Component } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button } from '@mui/material'
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

export class AboutDialog extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open}>
                <DialogTitle>EV Buddy ({process.env.REACT_APP_VERSION})</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            A map built to show electric vehicle charging locations.
                            <br />
                            Data provided by the <a target="_blank" rel="noreferrer" href="https://www.gov.uk/guidance/find-and-use-data-on-public-electric-vehicle-chargepoints">National Chargepoint Registry</a>.
                            <br />
                            Built and maintained by <a target="_blank" rel="noreferrer" href="https://darrylbayliss.net">Darryl Bayliss</a>.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
            );
    }
}

AboutDialog.propTypes = {
    open: PropTypes.bool.isRequired,
};