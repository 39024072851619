import React, { Component } from 'react'
import { Box, Card, Typography, Link } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

class ConnectorsSection extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return(
            <ThemeProvider theme={theme}>
            <Card sx={{ m: 2 }}>
                <Box sx= {{ m: 2}}>
                <Typography variant="h6"> 🔌 Connectors</Typography>
                    { this.props.electricCharger.Connector.map( (connector, index) =>
                        <Stack key={Math.random()}>
                            <Typography> {"#"}{index+1}</Typography>
                            <Typography> {"Type:"} {connector.ConnectorType}</Typography>
                            <Typography> {"Status:"} {connector.ChargePointStatus}</Typography>
                            <Typography> {"Output (kW):"} {connector.RatedOutputkW}</Typography>
                            <Typography> {"Output (Voltage):"} {connector.RatedOutputVoltage}</Typography>
                            <Typography> {"Output (Current):"} {connector.RatedOutputCurrent}</Typography>
                            <Typography> {"Charge Method:"} {connector.ChargeMethod}</Typography>
                            <Typography> {"Tethered:"} { connector.TetheredCable ? "Yes" : "No"} </Typography>
                            <Box sx={{ m: 2 }} />
                        </Stack>
                    )
                    }
                    <Box sx= {{ marginTop: 2}}>
                        <Typography variant="body1">{ "Confused? Check out this handy guide from the" } <Link target="_blank" rel="noreferrer" href="https://energysavingtrust.org.uk/advice/charging-electric-vehicles/">{"Energy Saving Trust"} </Link></Typography>
                    </Box>
                </Box>
            </Card>
            </ThemeProvider>
        )
    }
}

export default ConnectorsSection