import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContentText, DialogContent, Button } from '@mui/material'
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

export class ComingSoonDialog extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open}>
                    <DialogContent>
                        <DialogContentText>
                            Support for more countries coming soon!
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.closeDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
            );
    }
}

ComingSoonDialog.propTypes = {
    open: PropTypes.bool.isRequired,
};