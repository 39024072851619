import React, { Component } from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import LocationSection from './LocationSection'
import ConnectorsSection from './ConnectorsSection';
import OwnerSection from './OwnerSection';
import ControllerSection from './ControllerSection';
import PaymentSection from './PaymentSection';
import ActionSection from './ActionSection.js';

class ElectricChargerDetail extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        return(
            <React.Fragment>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid xs={12}>
                        <ActionSection electricCharger={this.props.electricCharger}
                        onMapsDirectionsClicked={this.props.onMapsDirectionsClicked}
                        onCloseChargerInfo={this.props.onCloseChargerInfo}/>
                    </Grid>
                    <Grid xs={12}>
                        <LocationSection electricCharger={this.props.electricCharger}/>
                    </Grid>
                    <Grid xs={12}>
                        <ConnectorsSection electricCharger={this.props.electricCharger}/>
                    </Grid>
                    <Grid xs={12}>
                        <PaymentSection electricCharger={this.props.electricCharger}/>
                    </Grid>
                    <Grid xs={12}>
                        <OwnerSection electricCharger={this.props.electricCharger}/>
                    </Grid>
                    <Grid xs={12}>
                        <ControllerSection electricCharger={this.props.electricCharger}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ElectricChargerDetail