import React from 'react'
import ReactDOM from 'react-dom'
import App from './pages/App'
import 'mapbox-gl/dist/mapbox-gl.css'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
      <App/>
  </React.StrictMode>,
  document.getElementById('root')
)
