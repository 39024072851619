import React, { Component } from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import LocationSection from './LocationSection'
import ConnectorsSection from './ConnectorsSection';
import PaymentSection from './PaymentSection';
import OwnerSection from './OwnerSection';
import ControllerSection from './ControllerSection';
import ActionSection from './ActionSection.js';

class ElectricChargerDetailDrawer extends Component {

  constructor(props) {
    super(props)
    this.state = {
        open: true
      }

    this.closeDrawer = this.closeDrawer.bind(this)
  }

  closeDrawer (event) {
    this.setState({ open: false })
    this.props.onDrawerClosed()
  }

  render() {
    return (
    <Drawer
        anchor='right'
        open={this.state.open}
        onClose={this.closeDrawer}
        onKeyDown={this.closeDrawer}>
        <List>
        <ActionSection electricCharger={this.props.electricCharger}
                        onMapsDirectionsClicked={this.props.onMapsDirectionsClicked}
                        onCloseChargerInfo={this.closeDrawer}/>
        <LocationSection electricCharger={this.props.electricCharger}/>
        <ConnectorsSection electricCharger={this.props.electricCharger}/>
        <PaymentSection electricCharger={this.props.electricCharger}/>
        <OwnerSection electricCharger={this.props.electricCharger}/>
        <ControllerSection electricCharger={this.props.electricCharger}/>
        </List>
    </Drawer>
    )
  }
}

export default ElectricChargerDetailDrawer