import React, { Component, Fragment } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import SearchIcon from '@mui/icons-material/Search'
import { styled, alpha } from '@mui/material/styles'
import { KofiButton } from 'react-kofi-button'
import InputBase from '@mui/material/InputBase'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'
import { Box, FormControl, NativeSelect } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1.5, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(2em + ${theme.spacing(2)})`,
    transition: theme.transitions.create('width'),
    width: '22ch'
  }
}))

const CountrySelectInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow'])
  },
}));

class NavBar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      postcode: '',
    }

    this.onHandleSearch = this.onHandleSearch.bind(this)
    this.updatePostcode = this.updatePostcode.bind(this)
    this.onAboutTapped = this.onAboutTapped.bind(this)
    this.countrySelected = this.onCountrySelected.bind(this)
    this.onFilterTapped = this.onFilterTapped.bind(this)
  }

  updatePostcode (event) {
    this.setState({ postcode: event.target.value})
  }

  onHandleSearch (event) {
    this.setState({ loading: true })
    this.props.onSearchButtonTapped(this.state.postcode)
  }

  onAboutTapped () {
    this.props.onAboutTapped()
  }

  onFilterTapped (event) {
    this.props.onFilterTapped(event.currentTarget)
  }

  onCountrySelected (event) {
    this.setState({selectedCountry: "🇬🇧"})
    this.props.onCountrySelected()
  }

  render () {
    return (
        <Fragment>
          <ThemeProvider theme={theme}>
           <AppBar position="static">
            <Toolbar>
            <Box sx={{display: { xs: 'none', md: 'flex'}}}>
            <FormControl sx={{ paddingRight: 2 }} >
            <NativeSelect sx={{ display: { xs: 'none', md: 'flex' } }}
              id="country-select"
              value={this.state.selectedCountry}
              defaultValue="🇬🇧"
              onChange={this.countrySelected}
              input={ <CountrySelectInput /> }>
              <option value="🇬🇧">🇬🇧</option>
              <option value="🌍">🌍</option>
            </NativeSelect>
              </FormControl>
              </Box>
              <Typography variant="h6" component="div" sx={{ mr: 2, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>EV Buddy</Typography>
              <Stack direction="row" spacing={1}>
              <Search sx={{ display: { xs: 'flex' ,md: 'flex' } }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    type="text"
                    placeholder="Enter Postcode (NW1 8AF)"
                    value={this.state.postcode}
                    onInput={this.updatePostcode}
                    onSubmit={this.onHandleSearch}
                  >
                  </StyledInputBase>
                </Search>
                {this.props.loading && <CircularProgress color="inherit"/>}
                <Button onClick={this.onHandleSearch} variant="contained">Search</Button>
                <Button onClick={this.onFilterTapped} variant="contained">Filter</Button>
                <Button sx={ { display: { xs: 'none', md: 'flex' } } } onClick={this.onAboutTapped} variant="contained">About</Button>
                <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
                <KofiButton username="DarrylBayliss" label="Support This Site" backgroundColor="kofiRed" />
                </Box>
              </Stack>
            </Toolbar>
          </AppBar>
          </ThemeProvider>
        </Fragment>
    )
  }
}

export default NavBar
