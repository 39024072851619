import React, { Component } from 'react'
import { Box, Card, Typography, Link } from '@mui/material';

class PaymentSection extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        let paymentRequiredString = "Yes"
        
        if (this.props.electricCharger.PaymentRequiredFlag) {
            paymentRequiredString = "Yes"
        } else {
            paymentRequiredString = "No"
        }

        let subscriptionRequiredString = "Yes"

        if (this.props.electricCharger.SubscriptionRequiredFlag) {
            subscriptionRequiredString = "Yes"
        } else {
            subscriptionRequiredString = "No"
        }

        let parkingFeesString = "Yes"

        if (this.props.electricCharger.ParkingFeesFlag) {
            parkingFeesString = "Yes"
        } else {
            parkingFeesString = "No"
        
        }

        return(
        <Card  sx={{ m: 2 }}>
            <Box sx= {{ m: 2}}>
            <Typography variant="h6">💷 Fees</Typography>
            <Typography variant="body1">{"Payment Required:"} { paymentRequiredString }</Typography>
            {this.props.electricCharger.PaymentRequiredFlag == true &&
                <Typography variant="body1">{"Payment Details:"} <Link href={this.props.electricCharger.PaymentDetails}>{this.props.electricCharger.PaymentDetails}</Link></Typography>
            }

            <Typography variant="body1">{"Subscription Required:"} { subscriptionRequiredString }</Typography>
            {this.props.electricCharger.SubscriptionRequiredFlag == true &&
                <Typography variant="body1">{"Subscription Details:"} <Link href={this.props.electricCharger.SubscriptionDetails}>{this.props.electricCharger.PaymentDetails}</Link></Typography>
            }

            <Typography variant="body1">{"Parking Fees:"} { parkingFeesString}</Typography>
            {this.props.electricCharger.ParkingFeesFlag == true &&
                <Typography variant="body1">{"Parking Fees Details:"} <Link href={this.props.electricCharger.ParkingFeesDetails}>{this.props.electricCharger.ParkingFeesDetails}</Link></Typography>
            }
            {this.props.electricCharger.ParkingFeesFlag == true &&
                <Typography variant="body1">{"Parking Fees Url:"} <Link href={this.props.electricCharger.ParkingFeesUrl}>{this.props.electricCharger.ParkingFeesUrl}</Link></Typography>
            }
            </Box>
            </Card>)
    }
}

export default PaymentSection