import React, { Component } from 'react'
import { Stack, Button} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../theme'

class ActionSection extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        var postcode = this.props.electricCharger.ChargeDeviceLocation.Address.PostCode

        return(
            <ThemeProvider theme={theme}>
            <Stack direction="row" spacing={1} sx={{ m: 2 }}>
                <Button size="small" sx={{ width: 1 }} onClick= { e =>
                    {
                        e.stopPropagation()
                        this.props.onMapsDirectionsClicked(postcode, "apple")
                    }
                } variant="contained">Directions via Apple Maps
                </Button>

                <Button size="small" sx={{ width: 1 }} onClick= { e => 
                {
                    e.stopPropagation()
                    this.props.onMapsDirectionsClicked(postcode, "google")
                }
            } variant="contained">Directions via Google Maps</Button>

            <Button size="small" sx={{ width: 1 }} onClick={ e => {
                e.stopPropagation()
                this.props.onCloseChargerInfo()
            }} variant="contained">Back
            </Button>
            </Stack>
            </ThemeProvider>
        )
    }
}

export default ActionSection